@font-face {
  font-family: 'SFProDisplay';
  src: url('./utils/font/SFProDisplay-Medium.eot');
  src: url('./utils/font/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProDisplay-Medium.woff2') format('woff2'),
      url('./utils/font/SFProDisplay-Medium.woff') format('woff'),
      url('./utils/font/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('./utils/font/SFProDisplay-Bold.eot');
  src: url('./utils/font/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProDisplay-Bold.woff2') format('woff2'),
      url('./utils/font/SFProDisplay-Bold.woff') format('woff'),
      url('./utils/font/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('./utils/font/SFProDisplay-Regular.eot');
  src: url('./utils/font/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProDisplay-Regular.woff2') format('woff2'),
      url('./utils/font/SFProDisplay-Regular.woff') format('woff'),
      url('./utils/font/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./utils/font/SFPro-Regular.eot');
  src: url('./utils/font/SFPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFPro-Regular.woff2') format('woff2'),
      url('./utils/font/SFPro-Regular.woff') format('woff'),
      url('./utils/font/SFPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-BoldItalic.eot');
  src: url('./utils/font/SFProText-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-BoldItalic.woff2') format('woff2'),
      url('./utils/font/SFProText-BoldItalic.woff') format('woff'),
      url('./utils/font/SFProText-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-Bold.eot');
  src: url('./utils/font/SFProText-Bold.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-Bold.woff2') format('woff2'),
      url('./utils/font/SFProText-Bold.woff') format('woff'),
      url('./utils/font/SFProText-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-Heavy.eot');
  src: url('./utils/font/SFProText-Heavy.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-Heavy.woff2') format('woff2'),
      url('./utils/font/SFProText-Heavy.woff') format('woff'),
      url('./utils/font/SFProText-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-Light.eot');
  src: url('./utils/font/SFProText-Light.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-Light.woff2') format('woff2'),
      url('./utils/font/SFProText-Light.woff') format('woff'),
      url('./utils/font/SFProText-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-LightItalic.eot');
  src: url('./utils/font/SFProText-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-LightItalic.woff2') format('woff2'),
      url('./utils/font/SFProText-LightItalic.woff') format('woff'),
      url('./utils/font/SFProText-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-HeavyItalic.eot');
  src: url('./utils/font/SFProText-HeavyItalic.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-HeavyItalic.woff2') format('woff2'),
      url('./utils/font/SFProText-HeavyItalic.woff') format('woff'),
      url('./utils/font/SFProText-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-MediumItalic.eot');
  src: url('./utils/font/SFProText-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-MediumItalic.woff2') format('woff2'),
      url('./utils/font/SFProText-MediumItalic.woff') format('woff'),
      url('./utils/font/SFProText-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-Regular.eot');
  src: url('./utils/font/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-Regular.woff2') format('woff2'),
      url('./utils/font/SFProText-Regular.woff') format('woff'),
      url('./utils/font/SFProText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-Medium.eot');
  src: url('./utils/font/SFProText-Medium.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-Medium.woff2') format('woff2'),
      url('./utils/font/SFProText-Medium.woff') format('woff'),
      url('./utils/font/SFProText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-RegularItalic.eot');
  src: url('./utils/font/SFProText-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-RegularItalic.woff2') format('woff2'),
      url('./utils/font/SFProText-RegularItalic.woff') format('woff'),
      url('./utils/font/SFProText-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-Semibold.eot');
  src: url('./utils/font/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-Semibold.woff2') format('woff2'),
      url('./utils/font/SFProText-Semibold.woff') format('woff'),
      url('./utils/font/SFProText-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./utils/font/SFProText-SemiboldItalic.eot');
  src: url('./utils/font/SFProText-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
      url('./utils/font/SFProText-SemiboldItalic.woff2') format('woff2'),
      url('./utils/font/SFProText-SemiboldItalic.woff') format('woff'),
      url('./utils/font/SFProText-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}



body {
  margin: 0;
  background-color: #F6F7F9 !important;
  /* font-family: 'SFProDisplay', sans-serif !important; */
  font-family: 'SF Pro Text', sans-serif !important;
  letter-spacing: 0.4px;
}
* {
  /* font-family: 'SFProDisplay', sans-serif !important; */
  font-family: 'SF Pro Text', sans-serif !important;
  letter-spacing: 0.4px;

}
code {
  /* font-family: 'SFProDisplay', sans-serif !important; */
  font-family: 'SF Pro Text', sans-serif !important;

}
.root {
  background-color: red;
}